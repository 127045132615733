import styled from 'styled-components';

export * from 'styles/Detail.style';

export const Wrap = styled.div`
  .ant-input[disabled]{
    color: #252525;
    background-color: white;
  }
`;
